/* setup
* @Author: Jeff Shields
* @Date:   2019-01-20 06:48:13
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-02-17 09:50:18
*/

// bootstrap variables
$enable-caret:                                true;
$enable-rounded:                              true;
$enable-shadows:                              true;
$enable-gradients:                            false;
$enable-transitions:                          true;
$enable-prefers-reduced-motion-media-query:   true;
$enable-grid-classes:                         true;
$enable-print-styles:                         true;
$enable-validation-icons:                     true;
$enable-pointer-cursor-for-buttons:           true ;
$enable-responsive-font-sizes:                true ;
$enable-deprecation-messages:                 true ;

$spacer: 1rem;
// $h1-font-size: 3rem;
$card-columns-count: 2; // for sm

// add new spacers for fixed top navbar
$spacers: (
    6: ($spacer * 4)
);

// ========================
// project variables
// ========================

// on xl screen increase the overall font size
$html-fontsize-xl: 1.25rem;
$navbar-fontsize-xl: .8rem;
