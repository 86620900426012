/* !main
* @Author: Jeff Shields
* @Date:   2019-01-14 08:53:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-02-23 11:57:12
*/

// ===========================================================
@import "project/setup";


// ===========================================================
//                    Load themes
// ===========================================================
// @import "bs4themes/bootswatch/dist/xxxxx/variables";

// Required
@import "project/bs4-required";

// Your variable overrides go here, e.g.:
// @import "mythemes/xxxxx/variables";

@import "project/load-bs4";

// @import "bs4themes/bootswatch/dist/xxxxx/bootswatch";
